.App {
    text-align: center;
}

.profile-icon-button {
    padding: 0;
    flex-direction: column;
}

.ns-card {
    min-width: 275px;
    max-width: 325px;
    margin: 16px 8px;
    padding: 16px;
}

.ns-mini-card {
    min-width: 48px;
    max-width: 64px;
    aspect-ratio: 1;
    margin: 4px;
}


.bga-card {
    background-image: url("https://x.boardgamearena.net/data/themereleases/current/games/nextstation/221221-2031/img/stationcards.jpg");
    background-size: 400% 300%;
    border: .6vh solid #fff;
    border-radius: 5%;
    box-shadow: .4vh .4vh #37305f;
    box-sizing: border-box;
    height: 12.8vh;
    position: relative;
    width: 20vh;
}

.bga-icon-card {
    background-image: url("https://x.boardgamearena.net/data/themereleases/current/games/nextstation/221221-2031/img/icons.png");
    background-position: -300% -100%;
    background-size: 500% 300%;
    height: 6vh;
    width: 6vh;
    position: relative;
    left: 0;
    top: 0;
    border-width: 0.5vh;
    border-style: solid;
    border-radius: 5px;
    box-shadow: .4vh .4vh #37305f;
}

.bga-icon-card-dark {
    background-color: #121212;
}

.bga-icon-card-selected {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Indie Flower", cursive;
    font-size: 7.5vh;
    font-weight: bolder;
}

.invert {
    filter: invert(100%);
}